import React from "react"

function Sent({ ...props }) {
  return (
    <svg
      width={427}
      height={234}
      fill="none"
      viewBox="0 0 427 427"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M130.77 50.46c5.07-14.87 1-25.46-11.37-25.46-7.78 0-17.43 4.17-26.83 10.95-1.06-3.87-4.05-6.18-8.86-6.18-8.47 0-20.14 7.16-29.29 17.26a14 14 0 0 0-9.54-3.85c-8.11-.46-17.92 3.66-26.45 10.35a4.61 4.61 0 0 0-3.76-1.93c-4.67-.26-11 3.9-14.13 9.28-.19.33-.36.66-.54 1h133.23c.4-4.7-.43-8.62-2.46-11.42ZM154.84 113.43c2.48-7.26.51-12.43-5.55-12.43-3.81 0-8.52 2-13.11 5.35a4.063 4.063 0 0 0-4.33-3c-4.13 0-9.83 3.5-14.31 8.44a6.789 6.789 0 0 0-4.66-1.88c-4-.23-8.75 1.78-12.92 5.05a2.243 2.243 0 0 0-1.79-.96c-2.28-.13-5.37 1.9-6.9 4.53l-.27.48h65.08a8.168 8.168 0 0 0-1.24-5.58ZM416.09 28.72a11.321 11.321 0 0 0-1.55-.11c.48-7.25-2.29-11.89-8.22-11.89-4.27 0-9.52 2.42-14.86 6.51C392.99 9.15 388.09 0 376.99 0c-8.94 0-20.27 6-31.46 15.66-1-5.52-4.28-8.84-9.82-8.84-9.73 0-23.56 10.24-34.65 24.68a13.997 13.997 0 0 0-10.74-5.53c-9.26-.65-20.77 5.23-31 14.81a4.733 4.733 0 0 0-4.21-2.75c-5.07-.36-12 4.93-16.11 12h185.24c4.35-10.72.89-20.1-8.15-21.31Z"
        fill="#F0F0F0"
      />
      <path
        d="m91 128.626 227.97 32.82 30.59 30.87L91 128.626Z"
        fill="#D19821"
      />
      <path
        opacity={0.6}
        d="m91 128.626 227.97 32.82 30.59 30.87L91 128.626Z"
        fill="#fff"
      />
      <path
        d="m91 128.626 227.97 32.82-10.58 44.64L91 128.626Z"
        fill="#D19821"
      />
      <path
        opacity={0.2}
        d="m91 128.626 227.97 32.82-10.58 44.64L91 128.626Z"
        fill="#000"
      />
      <path
        d="m303.71 76.947 13.23 62a6.249 6.249 0 0 1-4.8 7.41l-118.08 25.2a6.258 6.258 0 0 1-7.41-4.8l-13.24-62a6.26 6.26 0 0 1 4.81-7.41l118.08-25.2a6.26 6.26 0 0 1 7.41 4.81v-.01Z"
        fill="#D19821"
      />
      <path
        opacity={0.4}
        d="m303.71 76.947 13.23 62a6.249 6.249 0 0 1-4.8 7.41l-118.08 25.2a6.258 6.258 0 0 1-7.41-4.8l-13.24-62a6.26 6.26 0 0 1 4.81-7.41l118.08-25.2a6.26 6.26 0 0 1 7.41 4.81v-.01Z"
        fill="#000"
      />
      <path
        d="m300.8 72.886-39.45 48.431a25.43 25.43 0 0 1-31.15 6.649l-55.79-28.1a6.222 6.222 0 0 1 3.81-2.53l118.08-25.2a6.23 6.23 0 0 1 4.5.75ZM91 128.626l188.03 44.69 29.36 32.77L91 128.626Z"
        fill="#D19821"
      />
      <path
        opacity={0.6}
        d="m91 128.626 188.03 44.69 29.36 32.77L91 128.626Z"
        fill="#fff"
      />
      <path
        d="m159.589 171.702-.505.862 20.924 12.257.506-.863-20.925-12.256ZM189.752 189.362l-.506.863 7.525 4.407.505-.863-7.524-4.407ZM91 128.626l188.03 44.69-36.55 44.05L91 128.626ZM342.5 233.327c-.253 0-.504-.047-.74-.14l-30.62-12.12a2.001 2.001 0 0 1 1.47-3.72l30.63 12.12a2.002 2.002 0 0 1-.74 3.86Z"
        fill="#D19821"
      />
      <path
        opacity={0.3}
        d="M342.5 233.327c-.253 0-.504-.047-.74-.14l-30.62-12.12a2.001 2.001 0 0 1 1.47-3.72l30.63 12.12a2.002 2.002 0 0 1-.74 3.86Z"
        fill="#000"
      />
      <path
        d="M340.69 215.767a1.925 1.925 0 0 1-.73-.15l-16.45-6.55a2.014 2.014 0 0 1-1.11-1.064 1.992 1.992 0 0 1 .398-2.195 2.006 2.006 0 0 1 1.412-.606c.264-.003.525.046.77.145l16.45 6.52a1.999 1.999 0 0 1 1.13 2.59 2.001 2.001 0 0 1-1.87 1.31Z"
        fill="#D19821"
      />
      <path
        opacity={0.5}
        d="M340.69 215.767a1.925 1.925 0 0 1-.73-.15l-16.45-6.55a2.014 2.014 0 0 1-1.11-1.064 1.992 1.992 0 0 1 .398-2.195 2.006 2.006 0 0 1 1.412-.606c.264-.003.525.046.77.145l16.45 6.52a1.999 1.999 0 0 1 1.13 2.59 2.001 2.001 0 0 1-1.87 1.31Z"
        fill="#fff"
      />
      <path
        d="M306.3 233.067a2.117 2.117 0 0 1-.73-.14l-29.87-11.86a2.014 2.014 0 0 1-1.11-1.064 1.992 1.992 0 0 1 .398-2.195 2.006 2.006 0 0 1 1.412-.606c.264-.003.525.046.77.145l29.87 11.82a2.001 2.001 0 0 1-.74 3.86v.04Z"
        fill="#D19821"
      />
      <path
        opacity={0.3}
        d="M306.3 233.067a2.117 2.117 0 0 1-.73-.14l-29.87-11.86a2.014 2.014 0 0 1-1.11-1.064 1.992 1.992 0 0 1 .398-2.195 2.006 2.006 0 0 1 1.412-.606c.264-.003.525.046.77.145l29.87 11.82a2.001 2.001 0 0 1-.74 3.86v.04Z"
        fill="#000"
      />
    </svg>
  )
}

export default Sent
